import axios from "../utils/request";

// *新闻列表
export let getNewsList = (params) => axios.post("news/getNews", params);

//*新增新闻
export let addNews = (params) => axios.post("news/addNews", params);

// *更新新闻
export let updateNews = (params) => axios.post("news/updateNews", params);

// *删除新闻
export let deleteNews = (params) => axios.post("news/removeNews", params);

//*通过id获取新闻详情
export let getNewsById = (params) => axios.post("news/getById", params);
