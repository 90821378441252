<template>
  <!-- 新闻列表 -->
  <div>
    <!-- 复选框操作  @selection-change="checkboxchange" -->
    <el-table ref="mytable" :data="list">
      <el-table-column label="新闻标题" width="500">
        <template slot-scope="title">
          <p class="texthide">
            {{ title.row.titleCn ? title.row.titleCn : title.row.titleEn }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="新闻图片">
        <template slot-scope="image">
          <img
            style="width: 100px; height: 100px"
            class=""
            :src="image.row.image"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="300"
      ></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="item">
          <el-button @click="clickEditNew(item.row)">编辑</el-button>
          <el-button type="danger" @click="clickRemove(item.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="mt30">
      <el-pagination
        @size-change="sizechange"
        @current-change="pagechange"
        :current-page="curpage"
        :page-sizes="changepagesizes"
        :page-size="curpagesize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNewsList, deleteNews } from "@/apis/news";
// updateNews
export default {
  components: {},
  props: {},
  data() {
    return {
      list: [],
      total: 0, //总条数
      changepagesizes: [5, 10, 15, 20, 25], //可以切换的条数数组
      curpage: 1, //当前页数
      curpagesize: 10, //当前选中的条数

      //*编辑的新闻数据
      editNew: {},
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.newTableData();
  },
  methods: {
    // *新闻列表
    newTableData() {
      getNewsList({ page: this.curpage, size: this.curpagesize }).then(
        (res) => {
          if (res.data.code == 0) {
            this.list = res.data.data.news;
            this.total = res.data.data.count;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        }
      );
    },
    //点击切换每页显示的条数
    sizechange(newsize) {
      this.curpagesize = newsize; //先改变新的显示条数
      this.newTableData(); //请求新数据！
    },
    //点击跳转页数
    pagechange(newpage) {
      this.curpage = newpage; //先把当前页数切换到新的页数
      this.newTableData(); //请求新页的数据
    },

    // *编辑新闻
    clickEditNew(val) {
      this.$router.push({
        path: "/index/newsadd",
        query: {
          id: val.id,
        },
      });
    },

    // *删除新闻
    clickRemove(val) {
      this.$confirm("确定删除该新闻吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNews({ id: val.id }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.newTableData();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
